/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import client from '../../../api/api';
import HumberDropdown from '../../../libs/humber-dropdown';
import UploadImgBtn from '../UploadImgBtn';
import SideInfo from './SideInfo';
import Success from './Success';
import InputProvider from './InputProvider';
import resizeImage from '../../../libs/resizer/imageResizer';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const CardWrap = styled.form`
  background: #1f1e29;
  width: 100%;
  margin: 26px auto;
  padding: 5px 12px;

  h2 {
    color: white;
    margin: 10px;
  }

  .clear-btn {
    border: none;
    background: #ffbb0f;
    font-size: 12px;
    border-radius: 20px;
    padding: 5px 17px;
    margin: 10px;
    color: gray;
  }

  .clear-btn:focus {
    outline: none;
  }

  ${media.desktop} {
    border-radius: 20px;
    margin: 25px;
    width: 27pc;

    .clear-btn {
      margin: 0 0 0 12px;
    }
  }
`;

const AddTruckerWrap = styled.div`
  color: #ffbb0f;
  padding: 2pc 0 15pc 0;
  margin: 0 auto;
  background-color: #31354e;
  display: flex;
  position: relative;
  flex-direction: column;

  .step-index {
    display: inline-flex;
    width: 14px;
    height: 90px;
    margin: 17px 18px 0 0;
  }

  .premium-icon {
    display: inline-flex;
    width: 5pc;
    height: 5pc;
    margin: 20px;
  }

  img {
    width: 100%;
  }

  h3 {
    font-size: 30px;
  }

  ul {
    display: inline-block;
    color: white;
  }

  ul li {
    margin: 12px 0;
    font-size: 16px;
  }

  .items-wrap {
    display: flex;
  }

  .billing-data-ad {
    display: none;
  }

  .save-btn {
    position: absolute;
    background: #ffbb0f;
    color: gray;
    border-radius: 40px;
    border: none;
    width: 11pc;
    bottom: 50px;
    height: 34px;
    font-size: 19px;
    right: 48px;
    outline: none;
  }

  label {
    width: 90%;
    font-weight: 600;
    color: gray;
    margin: 10px 15px;
  }

  input {
    background: transparent;
    border: none;
    height: 45px;
    font-size: 16px;
    color: white;
    width: 100%;
    border-bottom: 1px solid #787878;
  }

  input:focus {
    outline: none;
  }

  ${media.tablet} {
    .premium-icon {
      margin: unset;
    }
  }

  ${media.desktop} {
    width: 100%;
    position: relative;

    .premium-icon {
      width: 6pc;
      height: 6pc;
      margin-right: 10px;
    }

    h3 {
      width: 47%;
    }

    ul li {
      margin: 15px 0;
      font-size: 16px;
    }

    .side-info {
      position: absolute;
      height: 79pc;
      background: #1f2231;
      width: 28pc;
      top: 0;
      right: 0;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }

    .billing-data-ad {
      display: flex;
      width: 100%;
      height: 13pc;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .billing-data-ad p {
      color: white;
      padding: 20px;
    }

    .money-icon {
      width: 5pc;
      margin-left: 40px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  position: relative;
  flex-direction: column;

  .truck-info {
    flex-direction: column;
  }

  ${media.desktop} {
    width: 60pc;
    flex-direction: row;

    .truck-info {
      width: 30pc;
      margin: 0;
    }
  }
`;

const Header = styled.div`
  margin: 0 auto;
  flex-direction: column;

  h3 {
    width: 100%;
    padding-left: 25px;
  }

  ${media.tablet} {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    img {
      width: 5pc;
      height: 5pc;
    }

    .premium-icon {
      margin: 17px;
    }
  }

  ${media.desktop} {
    display: flex;
    flex-direction: column;
    width: 60pc;

    img {
      width: 6pc;
      height: 6pc;
      margin: unset;
    }
  }
`;

const BillingInfo = styled.div`
  height: 2pc;
  width: 100%;
  padding: 0 16px;
  font-size: 12px;

  p {
    color: white;
  }
`;

const ErrorWrap = styled.div`
  width: 100%;
  background: red;
  display: flex;
  justify-content: center;

  p {
    color: white;
    padding: 10px;
  }
`;

const AddTrucker = () => {
  const [truckerCuit, setTruckerCuit] = useState('');
  const [truckerName, setTruckerName] = useState('');
  const [cellNumber, setCellNumber] = useState('');
  const [billingCuit, setBillingCuit] = useState('');
  const [legalName, setLegalName] = useState('');
  const [cbu, setCBU] = useState('');
  const [truckPlate, setTruckPlate] = useState('');
  const [trailerPlate, setTrailerPlate] = useState('');
  const [trailerBrand, setTrailerBrand] = useState('');
  const [trailerModel, setTrailerModel] = useState('');
  const [trailerBoxType, setTrailerBoxType] = useState('');
  const [trailerModelYear, setTrailerModelYear] = useState('');
  const [trailerAxles, setTrailerAxles] = useState(0);
  const [bodyTypeList, setBodyTypeList] = useState([]);
  const [bodyTypeSelected, setBodyTypeSelected] = useState([]);
  const [truckTypeList, setTruckTypeList] = useState([]);
  const [truckTypeSelected, setTruckTypeSelected] = useState([]);
  const [billingDataId, setBillingDataId] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [truckId, setTruckId] = useState('');

  const [psychophysicalImg, setPsychophysicalImg] = useState('');
  const [frontTractorIdPhoto, setFrontTractorIdPhoto] = useState('');
  const [trailerIdPhoto, setTrailerIdPhoto] = useState('');
  
  const [photosArr, setPhotosArr] = useState([]);
  const [truckerCreated, setTruckerCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingDataBtnDisabled, setBillingDataBtnDisabled] = useState(false);

  const errorRef = useRef(null);
  
  const trucker = {
    truckerName,
    truckerCuit,
    cellNumber,
    billingCuit,
    cbu,
    legalName,
    truckPlate,
    trailerPlate,
    trailerBrand,
    trailerModel,
    trailerBoxType,
    trailerModelYear,
    trailerAxles,
    bodyTypeSelected,
  };

  const fetchLists = async () => {
    try {
      const bodyTypeListResponse = await client.endpoints.carrier.getBodyTypeList();
      const bodyTypeTmpSelected = bodyTypeListResponse.data.map(({ name, ...rest }) => ({
        rest,
        label: name
      }));
      setBodyTypeList(bodyTypeTmpSelected);
      
      const truckTypeListResponse = await client.endpoints.carrier.getTruckTypes();
      const truckTypeTmpSelected = truckTypeListResponse.data.map(({ name, ...rest }) => ({
        rest,
        label: name
      }));
      setTruckTypeList(truckTypeTmpSelected);
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const cuitValidation = async cuit => {
    try {
      setErrorMsg('');
      if (cuit.length !== 11 && cuit !== '') {
        setErrorMsg('El CUIT debe tener 11 dígitos');
        return;
      }

      if (cuit !== '' && truckerName === '') {
        const res = await client.endpoints.carrier.getTruckerByCUIT(cuit);
        if (res.status === 200 && res.data._id) {
          setErrorMsg('El camionero ya existe en Humber, ingresá otro CUIT');
          setBillingDataBtnDisabled(true);
        } else {
          setTruckerName(res.data.name);
        }
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const convertText = async text => {
    return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
  };

  const billingCuitValidation = async cuit => {
    try {
      setErrorMsg('');
      setLegalName('');
      if (cuit !== '') {
        const res = await client.endpoints.generic.validateBillingCUIT(cuit, false, true);
        if (res.status === 200) {
          setLegalName(res.data.name);
          setBillingDataId(res.data.billingDataId ? res.data.billingDataId : '');
        }
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const saveTrucker = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('truckerCuit', truckerCuit);
      formData.append('truckerName', truckerName);
      formData.append('truckerCellPhone', cellNumber);
      formData.append('truckPatent', truckPlate.toUpperCase()); //Queda como truckPatent (api2 method and constructor)
      formData.append('trailerBodyTypeId', bodyTypeSelected[0]?.id);
      formData.append('truckTypeId', truckTypeSelected[0]?.id);
      formData.append('trailerPatent', trailerPlate.toUpperCase()); //Queda como trailerPatent (api2 method and constructor)
      formData.append('provinceId', '2');
      formData.append('cityId', '3961');
      if (billingDataId) {
        formData.append('billingDataId', billingDataId);
      } else {
        formData.append('billingCuit', billingCuit);
        formData.append('bankAccount', cbu);
      }
      formData.append('satelliteTracking', false);
      const truckerForm = { formData };

      if (
        truckerCuit !== '' &&
        truckerName !== '' &&
        cellNumber !== '' &&
        truckPlate !== '' &&
        bodyTypeSelected !== '' &&
        truckTypeSelected !== '' &&
        trailerPlate !== '' &&
        psychophysicalImg !== '' &&
        frontTractorIdPhoto !== '' &&
        trailerIdPhoto !== ''
      ) {
        const res = await client.endpoints.carrier.addTrucker(truckerForm);
        if (res.status === 200) {
          setTruckId(res.data.newTruck._id);
        }
        if (res.status === 400) {
          setErrorMsg('Se produjo un error');
        }
      } else {
        setErrorMsg('Subí las 3 fotos obligatorias y completá todos los campos');
      }
    } catch (error) {
      console.log(error);
      setErrorMsg(error.response?.data.message);
    } finally {
      setLoading(false);
    }
  };

  const buildPhotosArray = () => {
    const photosList = [];
    if (psychophysicalImg !== '') {
      photosList.push({
        photoTag: 'Foto psicofísico',
        photoName: 'psychophysicalPhoto',
        photoFile: psychophysicalImg,
      });
    }
    if (frontTractorIdPhoto !== '') {
      photosList.push({
        photoTag: 'Cédula Tractor',
        photoName: 'frontTractorIdPhoto',
        photoFile: frontTractorIdPhoto,
      });
    }
    if (trailerIdPhoto !== '') {
      photosList.push({
        photoTag: 'Cédula Remolque',
        photoName: 'trailerIdPhoto',
        photoFile: trailerIdPhoto,
      });
    }
    return photosList;
  };

  const uploadPhotos = async () => {
    try {
      setErrorMsg('');
      const photosList = buildPhotosArray();
      photosList.forEach(async photo => {
        const { photoTag, photoName, photoFile } = photo;
        const resizedImage = await resizeImage({ file: photoFile });
        const formData = new FormData();
        formData.append('photoName', photoName);
        formData.append('photoFile', resizedImage);
        const data = { formData };
        const res = await client.endpoints.carrier.uploadPhotos(data, truckId);
        if (res.status === 200) {
          setPhotosArr(arr => [...arr, { nombre: photoTag, subida: true }]);
        }
        if (res.status === 400) {
          photosArr.push({ nombre: photoName, subida: false });
        }
        if (!photoFile || photoFile === '' || photoFile === null || photoFile === undefined) {
          photosArr.push({ nombre: photoName, subida: false });
        }
      });
      setTruckerCreated(true);
    } catch (error) {
      setErrorMsg(error.response.data);
    }
  };

  const createTruckType = async (name) => {
    try {
      setErrorMsg('');
      const truckType = await client.endpoints.carrier.createTruckType(name);
      return truckType;
    } catch(error) {
      setErrorMsg(error.response.data);
    }
  }

  const createTrailerType = async (name) => {
    try {
      setErrorMsg('');
      const trailerType = await client.endpoints.carrier.createTrailerType(name);
      return trailerType;
    } catch(error) {
      setErrorMsg(error.response.data);
    }
  }
 
  const getCnrtInfo = async (type) => {
    try {
      setErrorMsg('');
      const plate = (type == 'truck') ? truckPlate : trailerPlate;
      const { data, status } = await client.endpoints.carrier.getCnrtInfo(plate, type);
      if (status === 200) {
        if(type == 'trailer') { // trailer data (CNRT)
          let {
            ruta: { marcaChasis, modeloChasis, tipoCaja, anioModelo, cantEjes },
            rto: { tipoCarroceria },
          } = data.data.dominios[0],
            bodyTypeTmpSelected = [];

          tipoCarroceria = await convertText(tipoCarroceria);
          setTrailerBrand(marcaChasis);
          setTrailerModel(modeloChasis);
          setTrailerBoxType(tipoCaja);
          setTrailerModelYear(anioModelo);
          setTrailerAxles(cantEjes);
          bodyTypeList.map(value => {
            if(value.label === tipoCarroceria || value.label === tipoCarroceria.toUpperCase()) {
              bodyTypeTmpSelected.push({ id: value.rest.id, label: value.label });
              setBodyTypeSelected(bodyTypeTmpSelected)
            }
          })
          if(bodyTypeTmpSelected.length == 0) { //Si no existe en la DB (se crea)
            const trailerType = await createTrailerType(tipoCarroceria);
            if(trailerType.status === 200) {
              fetchLists();
              const { id, name } = trailerType.data;
              bodyTypeTmpSelected.push({id: id, label: name});
              setBodyTypeSelected(bodyTypeTmpSelected);
            }
          }
        } else { // truck data (CNRT)
          let {
            vehiculo: { tipoVehiculoDNRPA },
          } = data,
            truckTypeTmpSelected = [];
          truckTypeList.map(value => {
            if(tipoVehiculoDNRPA !== null && tipoVehiculoDNRPA !== undefined) {
              if(value.label == tipoVehiculoDNRPA || value.label.toUpperCase() == tipoVehiculoDNRPA.toUpperCase()) {
                truckTypeTmpSelected.push({ id: value.rest.id, label: value.label });
                setTruckTypeSelected(truckTypeTmpSelected)
              }
            }
          })
          if(truckTypeTmpSelected.length == 0) { //Si no existe en la DB (se crea)
            if(tipoVehiculoDNRPA !== null && tipoVehiculoDNRPA != "null") {
              const truckType = await createTruckType(tipoVehiculoDNRPA);
              if(truckType.status === 200) {
                fetchLists();
                const { id, name } = truckType.data;
                truckTypeTmpSelected.push({id: id, label: name});
                setTruckTypeSelected(truckTypeTmpSelected);
              }
            }
          }
        }
      } else {
        setErrorMsg(`No se pudieron obtener los datos de la patente ${plate}`);
      }
    } catch (error) {
      console.log("error ", error);
      setErrorMsg(error.response.data);
    }
  };

  useEffect(() => {
    if (truckId !== '') uploadPhotos();
  }, [truckId]);

  useEffect(() => {
    if(truckPlate.length >= 6) {
      getCnrtInfo('truck');
    } else {
      setTruckTypeSelected([]);
    }
  }, [truckPlate]);

  useEffect(() => {
    if(trailerPlate.length >= 6) {
      getCnrtInfo('trailer');
    } else {
      setBodyTypeSelected([]);
    }
  }, [trailerPlate]);

  useEffect(() => {
    if (errorMsg && errorRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errorMsg, errorRef]);

  useEffect(() => {
    fetchLists();
  }, []);

  return (
    <>
      {errorMsg ? (
        <ErrorWrap>
          <p ref={errorRef}>{errorMsg}</p>
        </ErrorWrap>
      ) : (
        ''
      )}
      {truckerCreated ? (
        <Success />
      ) : (
        <AddTruckerWrap>
          <Header>
            <h3>Hacé premium a tu camionero con solo 3 fotos</h3>
            <div className="items-wrap">
              <div className="premium-icon">
                <img src="../../../images/icons/icono-premium.png" alt="premium-icon" />
              </div>
              <div className="step-index">
                <img src="../../../images/icons/step-index.png" alt="step-icons" />
              </div>
              <ul>
                <li>Psicofísico</li>
                <li>Cédula tractor</li>
                <li>Cédula remolque</li>
              </ul>
            </div>
          </Header>
          <Row>
            <CardWrap>
              <h2>Chofer</h2>
              <InputProvider
                label="CUIT"
                value={truckerCuit}
                setValue={setTruckerCuit}
                id="cuit"
                onBlurValidation={cuitValidation}
                setErrorMsg={setErrorMsg}
                disabled={truckerCuit && truckerName}
              />
              <button
                className="clear-btn"
                type="button"
                onClick={() => {
                  setErrorMsg('');
                  setTruckerCuit('');
                  setTruckerName('');
                  setBillingDataBtnDisabled(false);
                }}
              >
                Ingresar otro CUIT
              </button>
              <button
                className="clear-btn"
                type="button"
                onClick={() => {
                  setErrorMsg('');
                  setBillingCuit(truckerCuit);
                  setLegalName(truckerName);
                }}
                disabled={billingDataBtnDisabled}
              >
                Usar como datos de facturación
              </button>
              <label htmlFor="trucker-name">
                Nombre chofer
                <input id="trucker-name" value={truckerName} disabled />
              </label>
              <InputProvider
                label="Celular"
                value={cellNumber}
                setValue={setCellNumber}
                id="cell-number"
                setErrorMsg={setErrorMsg}
              />
              <UploadImgBtn setImg={setPsychophysicalImg} label="Psicofísico" />
            </CardWrap>
            <CardWrap>
              <h2>Datos de facturación</h2>
              <InputProvider
                label="CUIT de facturación"
                value={billingCuit}
                setValue={setBillingCuit}
                id="cuit"
                setErrorMsg={setErrorMsg}
                onBlurValidation={billingCuitValidation}
              />
              <BillingInfo>
                <p>Ingresá un CUIT de facturación</p>
              </BillingInfo>
              <label htmlFor="legal-name">
                Razón social
                <input id="legal-name" value={legalName} disabled />
              </label>
              {!billingDataId ? (
                <InputProvider
                  label="CBU"
                  value={cbu}
                  setValue={setCBU}
                  id="cbu"
                  setErrorMsg={setErrorMsg}
                />
              ) : (
                <p>
                  El dato de facturación ya existe en Humber, no hace falta que ingreses el CBU.
                </p>
              )}
            </CardWrap>
          </Row>
          <Row>
            <div className="truck-info">
              <CardWrap>
                <h2>Tractor</h2>
                <InputProvider
                  label="Patente"
                  value={truckPlate}
                  setValue={setTruckPlate}
                  id="truck-plate"
                  setErrorMsg={setErrorMsg}
                />
                <HumberDropdown
                  list={truckTypeList}
                  onChange={setTruckTypeSelected}
                  label="Tipo de camión"
                  htmlFor="truck-type"
                  id="truck-type"
                  value={truckTypeSelected}
                  required
                />
                <UploadImgBtn setImg={setFrontTractorIdPhoto} label="Subí la foto de la cédula" />
              </CardWrap>
              <CardWrap>
                <h2>Remolque</h2>
                <InputProvider
                  label="Patente"
                  value={trailerPlate}
                  setValue={setTrailerPlate}
                  id="trailer-plate"
                  setErrorMsg={setErrorMsg}
                />
                <HumberDropdown
                  list={bodyTypeList}
                  onChange={setBodyTypeSelected}
                  label="Carroceria"
                  htmlFor="carroceria"
                  id="carroceria"
                  default
                  value={bodyTypeSelected}
                  required
                />
                <UploadImgBtn setImg={setTrailerIdPhoto} label="Subí la foto de la cédula" />
              </CardWrap>
            </div>
            <SideInfo trucker={trucker} />
            {!loading ? (
              <button className="save-btn" type="button" onClick={saveTrucker}>
                Guardar
              </button>
            ) : (
              ''
            )}
          </Row>
        </AddTruckerWrap>
      )}
    </>
  );
};

export default AddTrucker;
