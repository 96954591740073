/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import config from './config';
import { getSessionUserData } from '../libs/user';

const {
  API1_URL,
  ENDPOINT_URL: API_URL,
  PREMIUM_DOCS_BUCKET,
  HUMBER_BUCKET_URL,
  BUCKET_API_KEY,
  REPORTS,
  SETTLEMENTS_BUCKET,
  BILLING_BUCKET,
  LOAD_COMMENTS_BUCKET,
  WAYBILL_URL,
  BILLINGAPI_URL,
  LOADAPI_URL,
  TRUCKAPI_URL,
  USERSAPI_URL,
  TRUCKERAPI_URL,
  GOOGLE_API_URL,
  SHIPMENTAPI_URL,
} = config;

const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${getSessionUserData() ? getSessionUserData().access_token : ''}`,
    },
  };
};

const getOptions = (path, params) => {
  return axios.options(path, { ...getHeaders(), ...params });
};

const get = (path, params) => {
  return axios.get(path, { ...getHeaders(), ...params });
};

const post = (path, data) => axios.post(path, data || {}, getHeaders());

const patch = (path, data) => axios.patch(path, data, getHeaders());

const axiosDelete = (path, data) => axios.delete(path, data || {}, getHeaders());

function getFileFromS3(bucket, fileName) {
  return getOptions(`${HUMBER_BUCKET_URL}download`, {
    params: {
      bucket,
      key: fileName,
    },
    responseType: 'blob',
    headers: {
      Accept: 'application/json',
      Authorization: BUCKET_API_KEY,
    },
  });
}

const generic = {
  login: (username, password, userType) => {
    return post(
      `${API_URL}/oauth2/login?grant_type=password&logAs=${userType}&username=${encodeURIComponent(
        username
      )}&password=${encodeURIComponent(password)}`
    );
  },
  logout: () => {
    return post(`${API_URL}/oauth2/logout`);
  },
  isAuthenticated({ userId, scope }) {
    return get(`${API_URL}/oauth2/isAuthenticated?userId=${userId}&scope=${scope}`);
  },
  signIn: (signData, userTypeUrl) => post(`${API_URL}${userTypeUrl}`, signData),
  verify: ({ id, token }) => post(`${API_URL}/users/verify/${id}/${token}`),
  passwordResetRequest: email => post(`${API_URL}/oauth2/passwordRecover?email=${email}`),
  resetPassword: ({ id, token, newPassFormData }) =>
    post(`${API_URL}/oauth2/resetPassword/${id}/${token}`, newPassFormData),
  getImagesPremium: key =>
    getOptions(`${HUMBER_BUCKET_URL}download`, {
      params: {
        bucket: PREMIUM_DOCS_BUCKET,
        key,
      },
      responseType: 'blob',
      headers: {
        Accept: 'application/json',
        Authorization: BUCKET_API_KEY,
      },
    }),
  getReports: key =>
    getOptions(`${HUMBER_BUCKET_URL}download`, {
      params: {
        bucket: REPORTS,
        key,
      },
      responseType: 'blob',
      headers: {
        Accept: 'application/json',
        Authorization: BUCKET_API_KEY,
      },
    }),
  uploadFlatImage: flatImageData =>
    post(`${API1_URL}/amazonContainers/${LOAD_COMMENTS_BUCKET}/upload`, flatImageData),
  getLastWebVersion: () => get(`${API_URL}/getLastWebVersion`),
  validateBillingCUIT: (cuit, acceptCuil, isCompany) =>
    get(`${API_URL}/afip/${cuit}`, {
      params: {
        ...(acceptCuil && { acceptCuil }),
        ...(isCompany && { isCompany }),
      },
    }),
  getEnabledGasStationList: gasStationName => {
    return get(`${API_URL}/getEnabledGasStationList?q=${gasStationName}`);
  },
  getTerms: async () => {
    const res = await get(`${API_URL}/getTerms`);
    return res;
  },
  getTermsAdvance: async () => {
    const res = await get(`${API_URL}/getTermsAdvance`);
    return res;
  },
  getFare: async (distance, loadTypeId) => {
    return get(`${API_URL}/loads/getFare?distance=${distance}&loadTypeId=${loadTypeId}`);
  },
  afipDelegate: async cuit => {
    return get(`${WAYBILL_URL}/waybills/afipDelegate?cuit=${cuit}`);
  },
  waybillCommonDestinations: async ({ plantCode } = {}) => {
    return get(`${WAYBILL_URL}/waybills/commonDestinations?plantCode=${plantCode}`);
  },
  getWaybillPdf(cuit, ctg) {
    return get(`${WAYBILL_URL}/waybills/?cuit=${cuit}&ctg=${ctg}`);
  },
};

const shipper = {
  getById: (id = {}) => get(`${API_URL}/points/${id}`),
  addLoad: loadData =>
    post(`${API_URL}/shippers/${getSessionUserData().data._id}/loads`, loadData.formData),
  addLinkedLoad: (loadData, id = getSessionUserData().data._id) =>
    post(`${LOADAPI_URL}/shippers/${id}/linked-loads`, loadData),
  editLoad: (id, editData) =>
    patch(`${API_URL}/points/${getSessionUserData().data._id}/load/${id}`, editData),
  placesAutoComplete: inputValue => get(`${API_URL}/googlePlacesAutocomplete?q=${inputValue}`),
  getMarkerGeocode: city => get(`${API_URL}/getGeocode?city=${city}`),
  getProductTypes: () => get(`${API_URL}/loads/productTypes`),
  getUnits: () => get(`${API_URL}/getUnits`),
  getCityDistances: (fromId, toId) =>
    get(`${API_URL}/calculateDistance?origin=${fromId}&destination=${toId}`),
  getActiveLoads: () => get(`${API_URL}/shippers/${getSessionUserData().data._id}/activeLoads`),
  getLoadsHistory: (filters, withShipment) => {
    const { limit, page } = filters;
    return get(`${API_URL}/shippers/${getSessionUserData().data._id}/loads`, {
      params: { limit, page, withShipment },
    });
  },
  searchTruckerByCuit: truckerCuit => {
    const trucker = get(`${API_URL}/truckers/getTruckerByCUIT/${truckerCuit}`);
    return trucker;
  },
  searchTruckerInBlackList: truckerId =>
    get(`${USERSAPI_URL}/blacklist`, { params: { userId: truckerId } }),
  deletePendingLoads: id =>
    post(`${API_URL}/shippers/${getSessionUserData().data._id}/loads/${id}/close`),
  saveComments: (data, loadId) => {
    return patch(`${API_URL}/shippers/${getSessionUserData().data._id}/loads/${loadId}`, data);
  },
  searchDestinations: razonSocial => {
    return get(`${WAYBILL_URL}/waybills/destinationTaxData?razonSocial=${razonSocial}`);
  },
  searchAdresee: razonSocial => {
    return get(`${WAYBILL_URL}/waybills/addresseeTaxData?razonSocial=${razonSocial}`);
  },
  afipCities: (city, code) =>
    get(`${WAYBILL_URL}/waybills/localities?name=${city || ''}&localityCode=${code || ''}`),
  afipCitiesByProducer: (cuit, city) =>
    get(`${WAYBILL_URL}/waybills/getProducerLocalities?cuit=${cuit || ''}&name=${city || ''}`),
  afipActivePlants: cuit => get(`${WAYBILL_URL}/waybills/getActivePlants?cuit=${cuit}`),
  afipCreateWaybill: data => post(`${WAYBILL_URL}/waybills`, data),
  getShipperById: id => get(`${USERSAPI_URL}/shipper/${id}`),
};

const carrier = {
  acceptTerms: async () => {
    return post(`${API_URL}/users/${getSessionUserData().data._id}/acceptTerms`);
  },
  getById: (id, params = {}) => get(`${API_URL}/carriers/${id}`, params),
  newShipment: (shipmentId, truckers) =>
    post(`${API_URL}/carriers/${getSessionUserData().data._id}/shipment`, {
      shipmentId,
      truckers,
    }),
  addTrucker: truckerData => {
    return post(
      `${API_URL}/carriers/${getSessionUserData().data._id}/truckers`,
      truckerData.formData
    );
  },
  getCnrtInfo: (truckPatent, truckType) => { 
    return get(`${TRUCKAPI_URL}/truckerCnrtInfo/${truckPatent}/${truckType}`);
  },
  uploadPhotos: (photos, truckId) => {
    return post(`${API_URL}/trucks/${truckId}/photos`, photos.formData);
  },
  uploadShipmentPhotos: (truckerId, shipmentId, data) => {
    return post(
      `${API_URL}/carriers/${
        getSessionUserData().data._id
      }/truckers/${truckerId}/shipments/${shipmentId}/photos`,
      data.formData
    );
  },
  editTrucker: (truckerData, truckId) => {
    return patch(
      `${API_URL}/carriers/${getSessionUserData().data._id}/truckers/${truckId}`,
      truckerData
    );
  },
  editTruckerPhotos: (data, truckerId) => {
    return patch(`${API_URL}/trucks/${truckerId}/photos`, data);
  },
  getTruckers: () => get(`${API_URL}/carriers/${getSessionUserData().data._id}/truckers`),
  takeLoad: (loadId, truckersIds) => {
    return post(`${API_URL}/carriers/${getSessionUserData().data._id}/takeLoad/${loadId}`, {
      ids: truckersIds,
    });
  },
  createShipment: (loadId, truckerId, partialPaymentId) => {
    return post(`${SHIPMENTAPI_URL}/shipments`, {
      loadId,
      truckerUserId: truckerId,
      carrierUserId: getSessionUserData().data._id,
      ...(partialPaymentId && { partialPaymentId }),
    });
  },
  getAvailableLoads: userId =>
    get(`${LOADAPI_URL}/loads/availables`, {
      params: {
        userId,
      },
    }),
  getShipmentHistory: filters => {
    const {
      loadDate,
      loadType,
      productType,
      fromCity,
      toCity,
      truckerName,
      truckPatent,
      truckBodyType,
      limit,
      sorting,
      page,
      active,
    } = filters;
    const params = {
      params: {
        ...(limit && { limit }),
        ...(sorting && { sorting }),
        ...(page && { page }),
        ...(loadDate && { loadDate }),
        ...(loadType && { loadType }),
        ...(productType && { productType }),
        ...(fromCity && { fromCity }),
        ...(toCity && { toCity }),
        ...(truckerName && { truckerName }),
        ...(truckPatent && { truckPatent }),
        ...(truckBodyType && { truckBodyType }),
        ...(active && { active }),
      },
    };
    return get(`${SHIPMENTAPI_URL}/carriers/${getSessionUserData().data._id}/shipments`, params);
  },
  getInvoices: filters => {
    const {
      loadDate,
      loadType,
      productType,
      fromCity,
      toCity,
      truckerName,
      truckPatent,
      truckBodyType,
      limit,
      sorting,
      page,
    } = filters;
    const params = {
      params: {
        ...(limit && { limit }),
        ...(sorting && { sorting }),
        ...(page && { page }),
        ...(loadDate && { loadDate }),
        ...(loadType && { loadType }),
        ...(productType && { productType }),
        ...(fromCity && { fromCity }),
        ...(toCity && { toCity }),
        ...(truckerName && { truckerName }),
        ...(truckPatent && { truckPatent }),
        ...(truckBodyType && { truckBodyType }),
      },
    };
    return get(`${API_URL}/carriers/${getSessionUserData().data._id}/invoices`, params);
  },
  getInvoices2: filters => {
    const { limit, order, page, invoiceNumber, ownerId } = filters;
    const params = {
      params: {
        ...(limit && { limit }),
        ...(order && { sort: order }),
        ...(page && { page }),
        ...(invoiceNumber && { invoiceNumber }),
        ...(ownerId && { ownerId }),
      },
    };
    return get(`${BILLINGAPI_URL}/transport/invoices`, params);
  },
  getProvinces: () => get(`${API_URL}/provinces`),
  getTruckMakes: () => get(`${API_URL}/trucks/modelTypes`),
  getTruckTypes: () => get(`${TRUCKAPI_URL}/truck-types`), //manager
  getBodyTypeList: () => get(`${TRUCKAPI_URL}/trailer-types`),
  getTruckerByCUIT: cuit => get(`${API_URL}/truckers/getTruckerByCUIT/${cuit}`),
  createTrailerType: (name) => post(`${TRUCKAPI_URL}/trailer-type`, { name }),
  createTruckType: (name) => post(`${TRUCKAPI_URL}/truck-type`, { name }),
  getSettlementFile: fileName => {
    return getFileFromS3(SETTLEMENTS_BUCKET, fileName);
  },
  getInvoiceFiles: fileName => {
    return getFileFromS3(BILLING_BUCKET, fileName);
  },
  getDebitNotesFiles: fileName => {
    return getFileFromS3(BILLING_BUCKET, fileName);
  },
  createGasOrder: (gasOrder, shipmentId) => {
    return post(
      `${API_URL}/carriers/${getSessionUserData().data._id}/shipments/${shipmentId}/gasorder`,
      gasOrder
    );
  },
  advanceUrlCarrier: billingId => {
    return get(`${API_URL}/billing/${billingId}/carrier/advanceUrl`);
  },
  isSettlementAdvanceEnabled: () => {
    return get(`${API_URL}/billingData/carrier/settlementAdvance/enabled`);
  },
  acceptSettlementAdvance: (billingId, token) => {
    return axios.post(
      `${API_URL}/billing/${billingId}/acceptAdvance`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  createSettlementAdvance: (billingId, token) => {
    return axios.post(
      `${API_URL}/billing/${billingId}/createAdvance`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  rejectSettlementAdvance: (billingId, token) => {
    return axios.post(
      `${API_URL}/billing/${billingId}/rejectAdvance`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getBillingDataOptions: () => {
    return get(`${BILLINGAPI_URL}/${getSessionUserData().data._id}/billingData`);
  },

  getCheckingAccountData: (cuit, fromDate) => {
    return get(
      `${BILLINGAPI_URL}/${cuit}/checkingAccountBalance${fromDate ? `?from=${fromDate}` : ''}`
    );
  },

  getBalanceCompositionData: cuit => {
    return get(`${BILLINGAPI_URL}/${cuit}/accountBalanceComposition`);
  },
  getDocumentToDownload: (document, impresionName) => {
    return get(`${BILLINGAPI_URL}/${document}/${impresionName}/getInvoice`, {
      responseType: 'blob',
    });
  },

  getCarrierEnabledForLoad: loadId =>
    get(`${LOADAPI_URL}/loads/${loadId}/carriersenabled/${getSessionUserData().data._id}`),

  getTruckerEnabledForLoad: (loadId, truckerId) =>
    get(`${LOADAPI_URL}/loads/${loadId}/truckersenabled/${truckerId}`),

  getUpdatedGeocode: () =>
    get(`${USERSAPI_URL}/carriers/${getSessionUserData().data._id}/updateGeocode`),

  updateCarrierGeocode: geoCode =>
    patch(`${USERSAPI_URL}/carriers/${getSessionUserData().data._id}`, geoCode),

  getGeocode: (province, city) =>
    get(
      `${GOOGLE_API_URL}/json?address=${province},${city}&key=AIzaSyBnq1QcKZwrZYdEj2HuPsOKxQvrbm3_j5U`
    ),

  updateCarrier: data => patch(`${USERSAPI_URL}/carriers/${getSessionUserData().data._id}`, data),

  truckerHasVtvUpToDate: data => {
    const { plate, loadId } = data;
    return get(`${TRUCKERAPI_URL}/TruckerHasVtvUpToDate/${plate}/${loadId}`);
  },

  getPermissions: () => get(`${TRUCKERAPI_URL}/permissions`),

  getPermissionsByUserId: userId =>
    get(`${TRUCKERAPI_URL}/truckers/byUserId/${userId}/permissions`),

  updatePermissions: (userId, permissionIds) => {
    return patch(`${TRUCKERAPI_URL}/truckers/byUserId/${userId}/permissions`, {
      permissionIds,
    });
  },

  getUserData: () => get(`${USERSAPI_URL}/users/${getSessionUserData().data._id}`),

  sendWappMessage: (message, userIds) => {
    return post(`${TRUCKERAPI_URL}/truckers/sendAppDownloadSuggestion`, { message, userIds });
  },

  sendWappTrucker: (userId, from, to) => {
    return post(`${TRUCKERAPI_URL}/truckers/sendBillingDataConfirmation`, { userId, from, to });
  },

  getBackgroundLocation: truckerId => {
    return get(`${TRUCKERAPI_URL}/truckers/${truckerId}/checkBackgroundLocation`);
  },
  fbTokenToDB: (userId, token) => post(`${USERSAPI_URL}/firebaseTokens/${userId}/insert/${token}`),
  fbTokenLogOut: userId => axiosDelete(`${USERSAPI_URL}/firebaseTokens/${userId}/logout`),
};

const endpoints = { generic, shipper, carrier };

const client = {
  endpoints,
};

export default client;
